import React from "react"
import {Container,Row,Col} from "react-bootstrap";
import Layout from "../components/layout"
import SEO from "../components/seo"
import listingStyle from '../styles/listing.module.css'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'30px'}

const ListingPage = () => (
  <Layout>
    <SEO title="Listing" />
    <body className={listingStyle.font} >
    <Container style={background}>
      <Row>
        <Col md={12} lg={{span:10, offset:1}}>
          <h3 className={listingStyle.title} style={{color:'#E9BF3C'}}>Listing</h3> 
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={1}/>
        <Col md={12} lg={5}>
        {/* <h3 style={{color:'#E9BF3C'}}>Listing</h3>  */}
            <p style={{textAlign:'justify'}}>
            Selling a property at the highest and best price requires strategy. Most owners want a quick sale with as little 
            traffic and complication as possible. However, taking stock in the home and assessing the market value from the Buyer’s perspective is wise.  
            A skilled real estate agent, relying on years of experience walking through properties with qualified Buyers, 
            knows what excites Buyers as well as what turns them off.  Sometimes it is the simplest of fixes if there is a deterrent, 
            sometimes it takes a bit more work.
            </p>
            <p style={{textAlign:'justify'}} className={listingStyle.secondparagraph}>
            The price suffers greatly when condition is overlooked prior to listing because often those properties are bought by investors 
            who watch for homes that are listed as-is.  The investors are notorious for low ball offers and after purchase they hold the properties 
            for a short period of time, improve the condition,  and then flip those homes for a sale that is sometimes double or more their 
            investment.  Why would you want someone else to flip your property, when you can flip the condition before you sell?  We can help 
            you get the condition of your property ready to list so you can pocket the value instead of an investor!  Make sure your home is 
            ready to sell at the best price.
            </p>
        </Col>
        <Col md={12} lg={5}>
          <div style={{ 
                backgroundImage:`url("../../images/listing.jpg")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center',
                backgroundSize:"cover",
                // backgroundSize:"contain",
                width:'100%'}} className={listingStyle.img}/>

        </Col>
        <Col md={12} lg={1}/>
      </Row>

      <Row>
        <Col md={12} lg={1}/>
        <Col md={12} lg={10}>
            <p style={{textAlign:'justify'}} className={listingStyle.secondparagraphmovil}>
                The price suffers greatly when condition is overlooked prior to listing because often those properties are bought by investors 
                who watch for homes that are listed as-is.  The investors are notorious for low ball offers and after purchase they hold the properties 
                for a short period of time, improve the condition,  and then flip those homes for a sale that is sometimes double or more their 
                investment.  Why would you want someone else to flip your property, when you can flip the condition before you sell?  We can help 
                you get the condition of your property ready to list so you can pocket the value instead of an investor!  Make sure your home is 
                ready to sell at the best price.
            </p>
            <p style={{textAlign:'justify'}}>
            The three components of selling property are: location, condition, and price.  Since the home won’t be moved, 
            location is a set value and the prominent factor of pricing a home.  
            A good location enhances a home, a poor location detracts from the value with only a few tools available 
            to mitigate noise or views as warranted.
            </p>
            <p style={{textAlign:'justify'}}>
            Condition, on the other hand, is an area that can drastically influence the marketability of a home.  
            Condition of the home includes interior and exterior, and cosmetics and function.  
            Most homebuyers are not interested in improving a home either due to lack of skill or desire.  
            It is often shocking that paint color or overgrown landscaping can easily cause a Buyer to lose interest.  
            The only remedy for a home that needs condition improvements is to adjust the last component of selling a 
            property and that is to lower the price.
            </p>
            <p style={{textAlign:'justify'}}>So instead, contact Upstaging to get your home ready to market.  Don't get flipped by the investors, reap the rewards of your own home investment!</p>
        </Col>
        <Col md={12} lg={1}/>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default ListingPage